<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab
            title="보너스배당"
            active
          >
            <h4 class="">
              <feather-icon
                icon="BoxIcon"
                style="width: 18px; height: 18px"
              />
              보너스배당
              <b-badge
                variant="primary"
                style="font-size: xx-small"
              >
                DB 연계
              </b-badge>
            </h4>
            <hr>
            <b-table
              responsive="sm"
              show-empty
              small
              empty-text="No matching records found"
              :items="fetchSetEvent"
              :fields="fields"
              style="text-align: center !important; font-size: small"
            >
              <!-- Column: gameName -->
              <template #cell(gameName)="data">
                <div
                  class="text-info"
                  size="sm"
                  style="text-align: center; min-width: 4rem"
                >
                  {{ data.item.gameName }}
                </div>
              </template>
              <!-- Column: eventName -->
              <template #cell(eventName)="data">
                <b-form-input
                  v-if="fetchSetEvent"
                  size="sm"
                  style="text-align: left; color:rgb(229,166,48); min-width: 8rem"
                  :value="data.item.eventName"
                  @input="updateParam('fetchSetEvent','eventName', $event, data.index)"
                />
              </template>
              <!-- Column: minFolds -->
              <template #cell(minFolds)="data">
                <b-form-input
                  v-if="fetchSetEvent"
                  size="sm"
                  style="text-align: center; color:rgb(229,166,48); min-width: 4rem"
                  :value="data.item.minFolds"
                  @input="updateParam('fetchSetEvent','minFolds', $event, data.index)"
                />
              </template>
              <!-- Column: bonusOdds -->
              <template #cell(bonusOdds)="data">
                <b-form-input
                  v-if="fetchSetEvent"
                  size="sm"
                  style="text-align: right; color:rgb(229,166,48); min-width: 8rem"
                  :value="data.item.bonusOdds"
                  @input="updateParam('fetchSetEvent','bonusOdds', $event, data.index)"
                />
              </template>
              <!-- Column: active -->
              <template #cell(active)="data">
                <b-form-select
                  v-if="fetchSetEvent"
                  size="sm"
                  class="center"
                  style="text-align: center; color:rgb(229,166,48); min-width: 4rem"
                  type="number"
                  :value="data.item.active"
                  :options="options3"
                  @input="updateParam('fetchSetEvent','active', $event, data.index)"
                />
              </template>
            </b-table>
            <hr>
            <div class="row">
              <b-col cols="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  class="float-left"
                  @click="eventPopupClear"
                  v-b-modal.modal-sm
                >
                  추가
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click="submit('updateManySetEvent')"
                >
                  저장
                </b-button>
              </b-col>
            </div>
          </b-tab>
          <b-modal
            id="modal-sm"
            ref="my-modal"
            title="보너스 이벤트 생성"
            centered
            button-size="sm"
            size="sm"
            ok-title="추가"
            @ok="submit('insertSetEvent')"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="게임항목"
                  label-for="gameMenu"
                >
                  <b-form-select
                    id="gameMenu"
                    class="font-small-3 text-info"
                    size="sm"
                    v-model="insertGameName"
                    :options="options2"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="이벤트명"
                  label-for="eventMenu"
                >
                  <b-form-input
                    id="eventName"
                    class="font-small-3"
                    size="sm"
                    style="text-align: left; color:rgb(229,166,48); min-width: 5rem"
                    v-model="insertEventName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>

          <!-- 충전이벤트-->
          <b-tab title="충전이벤트">
            <h4 class="">
              <feather-icon
                icon="BoxIcon"
                style="width: 18px; height: 18px"
              />
              충전이벤트 (포인트지급)
              <b-badge
                variant="primary"
                style="font-size: xx-small"
              >
                DB 연계
              </b-badge>
            </h4>
            <hr>
            <b-table
              responsive
              show-empty
              empty-text="No matching records found"
              :items="fetchSetEventDeposit"
              :fields="fields3"
              style="text-align: center !important; font-size: small; height: fit-content"
            >
              <template #cell(eventItem)="data">
                <div
                  class="text-info"
                  size="sm"
                  style="text-align: left; min-width: 7rem"
                >
                  {{ data.item.eventItem }}
                </div>
              </template>
              <template #cell(eventStartDate)="data">
                <b-form-datepicker
                  v-if="fetchSetEventDeposit"
                  :value="data.item.eventStartDate"
                  boundary="window"
                  size="sm"
                  class="align-self-center"
                  style="text-align: center; color:rgb(229,166,48); min-width: 14rem"
                  nav-button-variant="primary"
                  @input="updateParam('fetchSetEventDeposit','eventStartDate', $event, data.index)"
                />
              </template>
              <template #cell(eventEndDate)="data">
                <b-form-datepicker
                  v-if="fetchSetEventDeposit"
                  :value="data.item.eventEndDate"
                  boundary="window"
                  size="sm"
                  style="text-align: center; color:rgb(229,166,48); min-width: 14rem"
                  nav-button-variant="primary"
                  @input="updateParam('fetchSetEventDeposit','eventEndDate', $event, data.index)"
                />
              </template>
              <template #cell(eventPercent)="data">
                <span class="d-inline-flex">
                  <b-form-input
                    v-if="fetchSetEventDeposit"
                    :value="data.item.eventPercent"
                    size="sm"
                    style="text-align: center; color:rgb(229,166,48); min-width: 4rem"
                    type="number"
                    @input="updateParam('fetchSetEventDeposit','eventPercent', $event, data.index)"
                  />
                  <div class="align-self-center">
                    %
                  </div>
                </span>
              </template>
              <template #cell(minDeposit)="data">
                <b-form-input
                  v-if="fetchSetEventDeposit"
                  :value="data.item.minDeposit"
                  size="sm"
                  style="text-align: right; color:rgb(229,166,48); min-width: 5rem"
                  type="number"
                  @input="updateParam('fetchSetEventDeposit','minDeposit', $event, data.index)"
                />
              </template>
              <template #cell(maxPoint)="data">
                <b-form-input
                  v-if="fetchSetEventDeposit"
                  :value="data.item.maxPoint"
                  size="sm"
                  style="text-align: right; color:rgb(229,166,48); min-width: 5rem"
                  type="number"
                  @input="updateParam('fetchSetEventDeposit','maxPoint', $event, data.index)"
                />
              </template>
              <template #cell(active)="data">
                <b-form-select
                  v-if="fetchSetEventDeposit"
                  :value="data.item.active"
                  size="sm"
                  class="center"
                  style="text-align: center; color:rgb(229,166,48); min-width: 4rem"
                  :options="options3"
                  @input="updateParam('fetchSetEventDeposit','active', $event, data.index)"
                />
              </template>
            </b-table>
            <hr>
            <div class="row">
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click="submit('updateManySetEventDeposit')"
                >
                  저장
                </b-button>
              </b-col>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCard, BFormGroup, BFormInput, BCol, BButton, BTable, BRow, BFormSelect, BFormDatepicker, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_SET_EVENT, FETCH_SET_EVENT_DEPOSIT } from '@/store/settings/action'
import { UPDATEMANY_SET_EVENT, INSERT_SET_EVENT, UPDATEMANY_SET_EVENT_DEPOSIT } from '@/store/settings/mutation'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BFormSelect,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BRow,
    BFormDatepicker,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      userIp: localStorage.getItem('ip'),
      insertGameName: 'Sports-Prematch',
      insertEventName: '',
      disabled: false,
      isHidden1: true, // 충전이벤트
      valueFrom: '', // datepicker
      formattedFrom: '', // datepicker
      selectedFrom: '', // datepicker
      valueTo: '', // datepicker
      formattedTo: '', // datepicker
      selectedTo: '', // datepicke
      fields: [
        {
          key: 'gameName', sortable: false, label: '게임항목', thClass: 'col1',
        },
        {
          key: 'eventName', sortable: false, label: '이벤트명', thClass: 'col2',
        },
        {
          key: 'minFolds', sortable: false, label: '최소 폴드', thClass: 'col3',
        },
        {
          key: 'bonusOdds', sortable: false, label: '보너스배당',
        },
        {
          key: 'active', sortable: false, label: '사용여부',
        },
      ],
      fields3: [
        { key: 'eventItem', sortable: false, label: '이벤트항목' },
        { key: 'eventStartDate', sortable: false, label: '시작일' },
        { key: 'eventEndDate', sortable: false, label: '종료일' },
        { key: 'eventPercent', sortable: false, label: '이벤트%' },
        { key: 'minDeposit', sortable: false, label: '최소충전(금액)' },
        { key: 'maxPoint', sortable: false, label: '최대지급(포인트)' },
        { key: 'active', sortable: false, label: '사용여부' },
      ],
      options2: [
        { value: 'Sports-Prematch', text: 'Sports-Prematch' },
        { value: 'Sports-Special', text: 'Sports-Special' },
        { value: 'Sports-Inplay', text: 'Sports-Inplay' },
      ],
      options3: [
        { value: 'false', text: 'N' },
        { value: 'true', text: 'Y' },
      ],
      info: {
        // 이벤트 포인트 (회원가입후 첫충전)
        eventFirstActive: 'N',
        eventFirstPeriodFrom: '2022-09-17',
        eventFirstPeriodTo: '2022-09-22',
        eventFirstPoint: 0,
        eventFirstCheckDepositMin: 0,
        eventFirstPointMax: 0,

        // 이벤트 포인트 (매일 첫충전)
        eventDailyActive: 'N',
        eventDailyPeriodFrom: '2022-09-18',
        eventDailyPeriodTo: '2022-09-23',
        eventDailyPoint: 0,
        eventDailyCheckDepositMin: 0,
        eventDailyPointMax: 0,

        // 이벤트 포인트 (매 충전)
        eventEveryActive: 'N',
        eventEveryPeriodFrom: '2022-09-19',
        eventEveryPeriodTo: '2022-09-24',
        eventEveryPoint: 0,
        eventEveryCheckDepositMin: 0,
        eventEveryPointMax: 0,
      },
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetEvent: 'fetchSetEvent',
      fetchSetEventDeposit: 'fetchSetEventDeposit',
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
      updateManySetEvent: 'updateManySetEvent',
      updateManySetEventDeposit: 'updateManySetEventDeposit',
    }),
    settingsStore() {
      return this.$store.state.settingsStore.fetchSetEventDeposit
    },
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    // this.siteAdminData = this.fetchSetSiteAdmin
    // this.authSite = this.fetchSetSiteAdmin.map(item => item.authSite)
    // this.siteSelected = this.authSite[0]
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetEvent: FETCH_SET_EVENT,
      $updateManySetEvent: UPDATEMANY_SET_EVENT,
      $insertSetEvent: INSERT_SET_EVENT,
      $fetchSetEventDeposit: FETCH_SET_EVENT_DEPOSIT,
      $updateManySetEventDeposit: UPDATEMANY_SET_EVENT_DEPOSIT,
    }),
    updateParam(t, e, v, i) {
      console.log(t)
      console.log(e)
      console.log(v)
      const events = {
        t, e, v, i,
      }
      // this.info[e] = v
      // store에 param을 update 한다
      this.$store.commit('settingsStore/updateManyParam', events)
    },
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchSetEvent({
        site: mySite,
      })
      await this.$fetchSetEventDeposit({
        site: mySite,
      })
    },
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      // console.log('addItems :', addItems)
      const mySite = this.siteSelected
      try {
        if (target === 'updateManySetEvent') {
          await this.$updateManySetEvent({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
        }
        if (target === 'insertSetEvent') {
          await this.$insertSetEvent({
            site: mySite,
            gameName: this.insertGameName,
            eventName: this.insertEventName,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
        }
        if (target === 'updateManySetEventDeposit') {
          await this.$updateManySetEventDeposit({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formattedFrom = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selectedFrom = ctx.selectedYMD
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formattedTo = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selectedTo = ctx.selectedYMD
    },
    eventPopupClear() {
      this.insertEventName = ''
    },
  },
}
</script>

<style scoped>
/*.modal-lg {*/
/*  max-width: 1100px !important;*/
/*  margin: auto;*/
/*}*/
.form-align-self-center {
  text-align-last: center;
}
</style>
